import React from "react";
import { graphql } from "gatsby";
import MainLayout from "../components/layout/MainLayout";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { get } from "../helpers/data";
import { PageProps } from "../typings";
import { ServiceDetailQuery } from "./__generated__/ServiceDetailQuery";
import ServiceDetail from "../components/services/ServiceDetail";
import Footer from "../components/general/Footer";
import Container from "../components/general/Container";
import SEO from "../components/SEO";

type ServiceDetailProps = {} & PageProps<ServiceDetailQuery> &
  WithStyles<typeof styles>;

const servicePage: React.FC<ServiceDetailProps> = ({
  classes,
  data,
  location
}) => {
  const service = get(data, "contentfulService");
  return (
    <MainLayout location={location} title={service.header}>
      <Container variant="expanded" color="transparent">
        <ServiceDetail serviceDetails={data} />
      </Container>
      <Footer />

      <SEO
        general={{
          title: service.header,
          contentType: "website",
          description: service.introDetail.childMarkdownRemark.excerpt,
          language: "de",
          path: location.pathname
        }}
      />
    </MainLayout>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    background: {
      paddingTop: "2vh",
      paddingBottom: "2vh",
      [theme.breakpoints.up("md")]: {
        minHeigth: "78vh"
      }
    },
    contentContainer: {
      padding: "0 2vw"
    },
    infoContainer: {
      padding: "0 2vw"
    },
    textLead: {
      fontStyle: "italic"
    },
    title: {
      margin: "1rem 0 0 0"
    },
    subTitle: {}
  });

export default withStyles(styles)(servicePage);

export const serviceDetailQuery = graphql`
  query ServiceDetailQuery($slug: String!) {
    contentfulService(slug: { eq: $slug }) {
      id
      subheader
      header
      topicName

      additionalInfo {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }

      topic {
        name
        title
        service {
          order
          header
          intro {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          topicName
          slug
          image {
            title
            description
            fluid(maxWidth: 1600) {
              ...ContentfulFluidImage
            }
          }
        }
      }

      heroImage {
        title
        description
        fluid(maxWidth: 1600) {
          ...ContentfulFluidImage
        }
      }
      introDetail {
        childMarkdownRemark {
          excerpt(pruneLength: 170)
          rawMarkdownBody
        }
      }
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
