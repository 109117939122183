import React, { useState } from "react";
import { findIndex } from "lodash-es";
import { compose } from "recompose";
import {
  Button,
  createStyles,
  withStyles,
  WithStyles,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem
} from "@material-ui/core";
import Typo from "../general/Typo";
import { ServiceDetailQuery_contentfulService } from "../../templates/__generated__/ServiceDetailQuery";
import { SpecificTheme } from "../../theme/default";
import TabPanel from "../general/TabPanel";
import Markdown from "../general/Markdown";

type ServiceDetailProps = {
  serviceDetails: ServiceDetailQuery_contentfulService;
};

type ComponentProps = ServiceDetailProps & WithStyles<typeof styles>;

const styles = (theme: SpecificTheme) =>
  createStyles({
    button: {},
    container: {
      marginTop: "3rem",
      // background: "#111",
      border: "2px solid #19718c",
      // borderWidth: "0 2px 0 2px",
      // borderRadius: "px",
      // boxShadow: "inset 1px 1px #19718c",
      [theme.breakpoints.up("md")]: {
        minHeight: "250px"
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "3rem"
      }
    },
    tabHeader: {
      backgroundColor: "#eeeeee"
    },
    title: {
      margin: "0 0",
      fontSize: "1.5rem",
      padding: "1rem"
    },
    select: {
      marginLeft: "1rem"
    }
  });

function a11yProps(index: any) {
  return {
    id: `topic-tab-${index}`,
    "aria-controls": `topic-tabpanel-${index}`
  };
}

const ServiceTopic: React.FC<ComponentProps> = props => {
  const { serviceDetails, classes } = props;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const initPosition: number = serviceDetails.topic
    ? findIndex(serviceDetails.topic.service, {
        topicName: serviceDetails.topicName
      })
    : -1;

  const [tab, setTab] = useState(initPosition);

  if (serviceDetails.topic) {
    const services = serviceDetails.topic.service;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);
    };

    const handleChangeMobile = (
      event: React.ChangeEvent<{ value: number }>
    ) => {
      setTab(event.target.value);
    };

    return (
      <div className={classes.container}>
        <Typo type="serviceSubheader" className={classes.title}>
          {serviceDetails.topic.title}
        </Typo>
        {/* Tabs for desktop and ipad */}
        {isLargeScreen && (
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="Verschiedene Eigenschaften des Service"
            className={classes.tabHeader}
          >
            {services.map((service, i) => {
              return (
                <Tab
                  key={service.topicName}
                  value={i}
                  label={service.topicName}
                  {...a11yProps(i)}
                />
              );
            })}
          </Tabs>
        )}
        {/* Handle mobile with drop down */}
        {!isLargeScreen && (
          <Select
            value={tab}
            onChange={handleChangeMobile}
            className={classes.select}
          >
            {services.map((service, i) => (
              <MenuItem key={service.topicName} value={i}>
                {service.topicName}
              </MenuItem>
            ))}
          </Select>
        )}
        {services.map((service, i) => {
          return (
            <TabPanel key={service.topicName} value={tab} index={i}>
              <Markdown
                source={service.intro.childMarkdownRemark.rawMarkdownBody}
              />

              {service.topicName !== serviceDetails.topicName && (
                <Button
                  variant="contained"
                  color="primary"
                  href={`/services/${service.slug}`}
                >
                  Mehr erfahren
                </Button>
              )}
            </TabPanel>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default compose<ComponentProps, ServiceDetailProps>(withStyles(styles))(
  ServiceTopic
);
