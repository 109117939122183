import React from "react";
import { compose } from "recompose";
import ReactMarkdown, { ReactMarkdownProps, Renderers } from "react-markdown";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { SpecificTheme } from "../../theme/default";

type MarkdownProps = {
  additionalRenderers?: Renderers;
  source: string | null;
} & Omit<ReactMarkdownProps, "source">;

type ComponentProps = MarkdownProps & WithStyles<typeof styles>;

const Markdown: React.FC<ComponentProps> = ({
  children: markdownChildren,
  source,
  additionalRenderers,
  classes,
  ...props
}) => {
  return (
    <ReactMarkdown
      renderers={{
        heading: p => {
          const { children, level } = p;
          return (
            <Typography
              variant={`h${level}` as "h1"}
              className={level <= 2 ? classes.notBold : ""}
            >
              {children}
            </Typography>
          );
        },
        paragraph: ({ children }) => (
          <Typography variant="body1">{children}</Typography>
        ),
        link: p => {
          const { children } = p;
          return (
            <Button variant="contained" color="primary" href={p.href}>
              {children}
            </Button>
          );
        },
        ...additionalRenderers
      }}
      source={source ? source : ""}
      {...props}
    />
  );
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    notBold: {
      fontWeight: 400
    }
  });

export default compose<ComponentProps, MarkdownProps>(withStyles(styles))(
  Markdown
);
