import React from "react";
import { compose } from "recompose";
import { withStyles, WithStyles, Grid, createStyles } from "@material-ui/core";
import { get } from "../../helpers/data";
import {
  ServiceDetailQuery,
  ServiceDetailQuery_contentfulService
} from "../../templates/__generated__/ServiceDetailQuery";
import Typo from "../general/Typo";
import BackgroundService from "./BackgroundService";
import { SpecificTheme } from "../../theme/default";
import Container from "../general/Container";
import Markdown from "../general/Markdown";
import { Image } from "../image/Image";
import ServiceTopic from "./ServiceTopic";

type ServiceDetailProps = {
  serviceDetails: ServiceDetailQuery;
};

type ComponentProps = ServiceDetailProps & WithStyles<typeof styles>;

const serviceDetail: React.FC<ComponentProps> = ({
  classes,
  serviceDetails
}) => {
  const service: ServiceDetailQuery_contentfulService = {
    ...get(serviceDetails, "contentfulService"),
    topic: get(serviceDetails, "contentfulService", "topic", "service")
      ? {
          ...get(serviceDetails, "contentfulService", "topic"),
          service: get(
            serviceDetails,
            "contentfulService",
            "topic",
            "service"
          ).sort((a, b) => a.order - b.order)
        }
      : undefined
  };
  return (
    <BackgroundService className={classes.background}>
      <Container
        variant="standard"
        margin
        color="white"
        className={classes.contentContainer}
      >
        <Grid container>
          <Grid item md={8}>
            <Image image={service.heroImage} className={classes.heroImage} />

            <Typo type="serviceSubheader">{service.subheader}</Typo>
            <Typo type="serviceHeader">{service.header}</Typo>

            <Markdown
              source={service.introDetail.childMarkdownRemark.rawMarkdownBody}
            />

            <Markdown
              source={service.content.childMarkdownRemark.rawMarkdownBody}
            />

            <ServiceTopic serviceDetails={service} />
          </Grid>

          <Grid item md={4} className={classes.infoContainer}>
            {service.additionalInfo &&
              service.additionalInfo.map(info => {
                return (
                  <React.Fragment key={info.title}>
                    <Typo type="serviceHeader" style={{ fontSize: "1.5rem" }}>
                      {info.title}
                    </Typo>
                    <Markdown
                      source={info.text.childMarkdownRemark.rawMarkdownBody}
                    />
                  </React.Fragment>
                );
              })}
          </Grid>
        </Grid>
      </Container>
    </BackgroundService>
  );
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    background: {
      paddingTop: "2vh",
      paddingBottom: "2vh",
      [theme.breakpoints.up("md")]: {
        minHeigth: "78vh"
      }
    },
    contentContainer: {
      padding: "2vw 2vw"
    },
    infoContainer: {
      padding: "0 2vw"
    },
    heroImage: {
      //  marginTop: "2vw"
    },
    title: {
      margin: "1rem 0 0 0"
    },
    subTitle: {}
  });

export default compose<ComponentProps, ServiceDetailProps>(withStyles(styles))(
  serviceDetail
);
